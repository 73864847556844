import { useAuth0 } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Container, MenuItem, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { DataTable } from 'components/DataTable/DataTable';
import { format } from 'date-fns';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { MRT_ColumnDef, MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PaginatedEnvelope } from 'types/paginatedEnvelope';
import { AuthUser } from 'types/user';
import { getUserId } from 'utils/getUserId';
import { useDataTable } from 'hooks/useDataTable';

import { StaffDialog } from './StaffDialog';
import { AgencyStaff } from './types/AgencyStaff';
import { DeleteStaffDialog } from './DeleteStaffDialog';

export const StaffOverview = () => {
  const theme = useTheme();
  const { user } = useAuth0<AuthUser>();
  const getTableData = useStatelessGet<PaginatedEnvelope<AgencyStaff>>(`/partner-api/agencies/${user?.userData.agencyId}/users`);
  const [isStaffDialogOpen, setIsStaffDialogOpen] = useState<boolean>(false);
  const [isDeleteStaffDialogOpen, setIsDeleteStaffDialogOpen] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [targetedStaff, setTargetedStaff] = useState<AgencyStaff | undefined>();
  const [staffVerb, setStaffVerb] = useState('delete');

  const columns = useMemo<MRT_ColumnDef<AgencyStaff>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: 'Name',
        Cell: ({ renderedCellValue, row }) => (
          <Link to={`/agencies/${row.original.agency_id}/users/${row.original.id}`} style={{ textDecoration: 'none' }}>
            <Typography sx={{ color: 'black' }} display="inline" mr={0.5}>
              {renderedCellValue}
            </Typography>
            {row.original.id === getUserId(user!) && (
              <Typography fontWeight="bold" sx={{ color: 'black' }} display="inline">
                (You)
              </Typography>
            )}
            <Typography sx={{ ...theme.mixins.secondaryText }}>{row.original.email}</Typography>
          </Link>
        ),
      },
      {
        accessorKey: 'roles',
        header: 'Role',
        Cell: ({ row }) => <Typography textTransform="capitalize">{row.original.roles.map((role) => role.replaceAll('-', ' ')).join(', ')}</Typography>,
        maxSize: 50,
        size: 50,
      },
      {
        // TODO: Wire up once confirmed in API response
        header: 'Status',
        accessorKey: 'deleted_at',
        Cell: () => <Typography>Coming soon...</Typography>,
        maxSize: 45,
        size: 45,
      },
      {
        accessorKey: 'last_active',
        header: 'Last Login',
        Cell: ({ row }) => (
          <Stack>
            {row.original.last_active !== null ? (
              <>
                <Typography sx={{ color: 'black' }} fontSize={16}>
                  {format(new Date(row.original.last_active), 'dd MMM yyyy')}
                </Typography>
                <Typography sx={{ ...theme.mixins.secondaryText }}>{format(new Date(row.original.last_active), 'h:mm a')}</Typography>
              </>
            ) : (
              <Typography sx={{ ...theme.mixins.secondaryText }}>Never</Typography>
            )}
          </Stack>
        ),
        maxSize: 50,
        size: 50,
      },
    ],
    [user, theme.mixins.secondaryText],
  );

  const rowActions = ({ row }: { row: MRT_Row<AgencyStaff> }) => [
    <MenuItem
      key="edit"
      onClick={() => {
        setTargetedStaff(row.original);
        setIsStaffDialogOpen(true);
      }}
    >
      Edit staff member
    </MenuItem>,
    <MenuItem
      key="disable"
      onClick={() => {
        setTargetedStaff(row.original);
        setStaffVerb('disable');
        setIsDeleteStaffDialogOpen(true);
      }}
    >
      Disable
    </MenuItem>,
  ];

  const containerStyle: SxProps = {
    // TODO: review responsive design width of containers
    maxWidth: {
      xl: '80vw',
      lg: '80vw',
      md: '80vw',
      sm: '80vw',
      xs: '80vw',
    },
    flex: 1,
  };

  const { table, refetch } = useDataTable<any>({
    tableKey: 'staff',
    getTableData: getTableData,
    columnDefinitions: columns,
    globalFilter,
    rowActions,
  });

  const newButton = (
    <Button variant="outlined" color="secondary" onClick={() => setIsStaffDialogOpen(true)} startIcon={<AddIcon color="secondary" />}>
      Add staff
    </Button>
  );

  const tableEntityName = {
    singular: 'staff',
    plural: 'staff',
  };

  if (!user) {
    return (
      <Box flex={1}>
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
          <CircularProgress variant="indeterminate" />
          <Typography>Loading&hellip;</Typography>
        </Stack>
      </Box>
    );
  }

  if (!user.userData.agencyId) {
    return (
      <Container sx={containerStyle}>
        <Box px={4} pb={4} pt={1}>
          <Typography color="error">User is not associated with an agency</Typography>
          <Button onClick={() => setIsDeleteStaffDialogOpen(true)}>Delete</Button>
        </Box>
      </Container>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <Box
        py={3}
        sx={{
          boxShadow: `0px 1px 2px 0px ${theme.palette.grey[200]}`,
        }}
      >
        <Container sx={containerStyle}>
          <Typography display="inline" variant="h4" component="h1" fontWeight="600" sx={{ ...theme.mixins.titleGradient, pl: 4 }}>
            {user.userData.agencyName}
          </Typography>
        </Container>
      </Box>

      <Container sx={containerStyle}>
        <Box px={4} pb={4} pt={1}>
          <DataTable
            table={table as MRT_TableInstance<MRT_RowData>}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            tableActions={newButton}
            entityName={tableEntityName}
          />
        </Box>
      </Container>
      {isStaffDialogOpen && (
        <StaffDialog
          active={isStaffDialogOpen}
          onToggle={() => {
            setIsStaffDialogOpen(false);
            setTargetedStaff(undefined);
            refetch();
          }}
          agencyId={user.userData.agencyId}
          staff={targetedStaff}
          userRoles={user.userData.roles}
        />
      )}
      {isDeleteStaffDialogOpen && (
        <DeleteStaffDialog
          active={isDeleteStaffDialogOpen}
          onToggle={() => {
            setIsDeleteStaffDialogOpen(false);
            setTargetedStaff(undefined);
            refetch();
          }}
          verb={staffVerb}
          staffName={`${targetedStaff?.first_name} ${targetedStaff?.last_name}`}
          agencyId={user.userData.agencyId}
          staffId={targetedStaff?.id}
        />
      )}
    </Box>
  );
};
