import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useCallback } from 'react';
import { useNotificationMessages } from 'utils/notifications';
import { getErrorMessage } from 'utils/errors';
import { ResponseEnvelope } from 'types/responseEnvelope';
import { useDelete } from 'hooks/useDelete';

interface DeleteStaffDialogProps {
  active: boolean;
  onToggle: () => void;
  verb?: string;
  staffName: string;
  staffId?: string;
  agencyId: string;
}

export const DeleteStaffDialog = ({ active, onToggle, verb = 'delete', staffName, staffId, agencyId }: DeleteStaffDialogProps) => {
  const [loading, deleteUser] = useDelete<ResponseEnvelope<any>>(`/partner-api/agencies/${agencyId}/users/${staffId}`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const deleteStaff = useCallback(async () => {
    try {
      const response = await deleteUser();
      showSuccessMessage(response.message);
      onToggle();
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [deleteUser, onToggle, showErrorMessage, showSuccessMessage]);

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Delete staff member
          </Typography>
          <IconButton aria-label="close" title="Close" onClick={onToggle} sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        Do you want to {verb} {staffName}?
      </DialogContent>
      <DialogActions>
        <Button onClick={onToggle}>Cancel</Button>
        <LoadingButton variant="contained" loading={loading} onClick={deleteStaff}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
